<template>
  <div class="register">
    <div class="error">
      <p v-for="item in error">{{ item }}</p>
    </div>
    <!-- 表单1  -->
    <div class="title">Account & Contact Info</div>
    <div class="font">
      <p>
        Your Account & Contact Information is used to login to the site. Please
        save your password in a safe place.
      </p>
    </div>
    <div class="form">
      <div class="T-form">
        <table width="100%">
          <tr class="tr">
            <td>*Company Name:</td>
            <td>
              <input type="text" v-model="Account.company" />
              <p></p>
            </td>
          </tr>
          <tr class="tr">
            <td>*First Name:</td>
            <td><input type="text" v-model="Account.first_name" /></td>
          </tr>
          <tr class="tr">
            <td>*Your Last Name:</td>
            <td><input type="text" v-model="Account.last_name" /></td>
          </tr>
          <tr class="tr">
            <td>*Your Email Address (and Username):</td>
            <td><input type="text" v-model="Account.email" /></td>
          </tr>
          <tr class="tr">
            <td>*Create a Personal Password:</td>
            <td><input type="password" v-model="Account.password" /></td>
          </tr>
          <tr class="tr">
            <td>*Re-Enter Your Password:</td>
            <td><input type="password" v-model="Account.password1" /></td>
          </tr>
          <tr class="tr">
            <td>*Tax ID:</td>
            <td><input type="text" v-model="Account.tax_id" /></td>
          </tr>
          <tr class="tr">
            <td>*Business phone:</td>
            <td><input type="text" v-model="Account.mobile" /></td>
          </tr>
          <tr class="tr">
            <td>Website:</td>
            <td><input type="text" v-model="Account.website" /></td>
          </tr>
          <tr class="tr">
            <td>Additional Email Address:</td>
            <td><input type="text" v-model="Account.additional_email" /></td>
          </tr>
          <tr class="tr">
            <td></td>
            <td>
              Please insert additional email addresses if you would like others
              to receive invoice and packing slips electronically. Please insert
              a semi colon and space between each unique address.
            </td>
          </tr>
        </table>
      </div>
    </div>
    <!-- 表单2  -->
    <div class="title">Billing Information</div>
    <div class="font">
      <p>Please enter your Billing Information below.</p>
      <span @click="copy1">same as account info</span>
    </div>
    <div class="form">
      <div class="T-form">
        <table width="100%">
          <tr class="tr">
            <td>*First Name:</td>
            <td><input type="text" v-model="Account.billing.first_name" /></td>
          </tr>
          <tr class="tr">
            <td>*Last Name:</td>
            <td><input type="text" v-model="Account.billing.last_name" /></td>
          </tr>
          <tr class="tr">
            <td>*Phone:</td>
            <td><input type="text" v-model="Account.billing.phone" /></td>
          </tr>
          <tr class="tr">
            <td>*Company:</td>
            <td><input type="type" v-model="Account.billing.company" /></td>
          </tr>
          <tr class="tr">
            <td>*Address Type:</td>
            <td>
              <select name="" id="" v-model="Account.billing.address_type">
                <option value="0">Unknown</option>
                <option value="1">Residential</option>
                <option value="2">Commercial</option>
              </select>
            </td>
          </tr>
          <tr class="tr">
            <td>*Address 1:</td>
            <td><input type="text" v-model="Account.billing.address1" /></td>
          </tr>
          <tr class="tr">
            <td>Address 2:</td>
            <td><input type="text" v-model="Account.billing.address2" /></td>
          </tr>
          <tr class="tr">
            <td>Suite:</td>
            <td><input type="text" v-model="Account.billing.suite" /></td>
          </tr>
          <tr class="tr">
            <td>*City or APO/AFO:</td>
            <td><input type="text" v-model="Account.billing.city" /></td>
          </tr>
          <tr class="tr">
            <td>*Country:</td>
            <td>
              <select v-model="Account.billing.country">
                <option v-for="(item, index) in options" :value="item.country">
                  {{ item.country }}
                </option>
              </select>
            </td>
          </tr>

          <tr class="tr">
            <td>*State/Province/Region:</td>
            <td>
              <select v-model="Account.billing.state">
                <!-- <option value="SELECT ONE">SELECT ONE</option> -->
                <option v-for="item in citys" :value="item.v">
                  {{ item.i }}
                </option>
              </select>
            </td>
          </tr>

          <tr class="tr">
            <td>*Zip/Postal Code:</td>
            <td>
              <input type="text" v-model="Account.billing.zip_code" />
            </td>
          </tr>
        </table>
      </div>
    </div>

    <!-- 表单3  -->
    <div class="title">Shipping Information</div>
    <div class="font">
      <span @click="copy2">same as billing info</span>
    </div>
    <div class="form">
      <div class="T-form">
        <table width="100%">
          <tr class="tr">
            <td>*First Name:</td>
            <td><input type="text" v-model="Account.shipping.first_name" /></td>
          </tr>
          <tr class="tr">
            <td>*Last Name:</td>
              <td><input type="text" v-model="Account.shipping.last_name" /></td>
          </tr>
          <tr class="tr">
            <td>*Phone:</td>
              <td><input type="text" v-model="Account.shipping.phone" /></td>
          </tr>
          <tr class="tr">
            <td>*Company:</td>
              <td><input type="text" v-model="Account.shipping.company" /></td>
          </tr>
          <tr class="tr">
            <td>*Address Type:</td>
            <td>
              <select name="" id="" v-model="Account.shipping.address_type">
                <option value="0">Unknown</option>
                <option value="1">Residential</option>
                <option value="2">Commercial</option>
              </select>
            </td>
          </tr>
          <tr class="tr">
            <td>*Address 1:</td>
             <td><input type="text" v-model="Account.shipping.address1" /></td>
          </tr>
          <tr class="tr">
            <td>Address 2:</td>
           <td><input type="text" v-model="Account.shipping.address2" /></td>
          </tr>
          <tr class="tr">
            <td>Suite:</td>
            <td><input type="text" v-model="Account.shipping.suite" /></td>
          </tr>
          <tr class="tr">
            <td>*City or APO/AFO:</td>
            <td><input type="text" v-model="Account.shipping.city" /></td>
          </tr>
          <tr class="tr">
            <td>*Country:</td>
            <td>
              <select v-model="Account.shipping.country">
                <option v-for="(item, index) in options" :value="item.country">
                  {{ item.country }}
                </option>
              </select>
            </td>
          </tr>

          <tr class="tr">
            <td>*State/Province/Region:</td>
            <td>
              <select v-model="Account.shipping.state">
                <!-- <option value="SELECT ONE">SELECT ONE</option> -->
                <option v-for="item in citys1" :value="item.v">
                  {{ item.i }}
                </option>
              </select>
            </td>
          </tr>

          <tr class="tr">
            <td>*Zip/Postal Code:</td>
            <td>
                <td><input type="text" v-model="Account.shipping.zip_code" /></td>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="button">
      <span @click="submit">Create Account</span>
    </div>
  </div>
</template>
<script >
import { register } from "network/user";
export default {
  watch: {
    "Account.billing.country"() {
      for (let i = 0; i < this.options.length; i++) {
        if (this.Account.billing.country == this.options[i].country) {
          this.citys = this.options[i].city;
          if (this.citys.length != 0) {
            this.Account.billing.state = this.citys[0].v;
          }
        }
      }
    },
    "Account.shipping.country"() {
      for (let i = 0; i < this.options.length; i++) {
        if (this.Account.shipping.country == this.options[i].country) {
          this.citys = this.options[i].city;
          if (this.citys.length != 0) {
            this.Account.shipping.state = this.citys1[0].v;
          }
        }
      }
    },
  },
  created() {
    this.citys = this.options[0].city;
    this.citys1 = this.options[0].city;
    this.Account.billing.address_type = "0";
    this.Account.billing.country = this.options[0].country;
    this.Account.billing.state = this.citys[0].v;

    this.Account.shipping.address_type = "0";
    this.Account.shipping.country = this.options[0].country;
    this.Account.shipping.state = this.citys1[0].v;

    // this.city = "SELECT ONE";
  },
  data() {
    return {
      clickButton: true,
      error: [],
      citys: [],
      citys1: [],
      options: [
        {
          country: "United States",
          city: [
            { v: "AA", i: "AA (Armed Forces Americas)" },
            { v: "AE", i: "AE (Armed Forces Europe)" },
            { v: "AL", i: "Alabama" },
            { v: "AE", i: "Alaska" },
            { v: "AS", i: "AS (Armed Forces Europe)" },
            { v: "AP", i: "AP (Armed Forces Pacific)" },
            { v: "AZ", i: "Arizona" },
            { v: "AR", i: "Arkansas" },
            { v: "CA", i: "California" },
            { v: "CO", i: "Colorado" },
            { v: "CT", i: "Connecticut" },
            { v: "DE", i: "Delaware " },
            { v: "DC", i: "District of Columbia " },
            { v: "FM", i: "Federated States of Micronesia " },
            { v: "FL", i: "Florida " },
            { v: "GA", i: "Georgia " },
            { v: "GU", i: "Guam " },
            { v: "HI", i: "Hawaii " },
            { v: "ID", i: "Idaho " },
            { v: "IL", i: "Illinois " },
            { v: "IN", i: "Indiana" },
            { v: "IA", i: "Iowa" },
            { v: "KS", i: "Kansas" },
            { v: "KY", i: "Kentucky" },
            { v: "LA", i: "Louisiana" },
            { v: "ME", i: "Maine" },
            { v: "MH", i: "Marshall Islands" },
            { v: "MD", i: "Maryland" },
            { v: "MA", i: "Massachusetts" },
            { v: "MI", i: "Michigan" },
            { v: "MN", i: "Minnesota" },
            { v: "MS", i: "Mississippi" },
            { v: "MO", i: "Missouri" },
            { v: "MT", i: "Montana" },
            { v: "NE", i: "Nebraska" },
            { v: "NV", i: "Nevada" },
            { v: "NH", i: "New Hampshire" },
            { v: "NJ", i: "New Jersey" },
            { v: "NM", i: "New Mexico" },
            { v: "NY", i: "New York" },
            { v: "NC", i: "North Carolina" },
            { v: "ND", i: "North Dakota" },
            { v: "MP", i: "Northern Mariana Islands" },
            { v: "OH", i: "Ohio" },
            { v: "OK", i: "Oklahoma" },
            { v: "OR", i: "Oregon" },
            { v: "PW", i: "Palau" },
            { v: "PA", i: "Pennsylvania" },
            { v: "PR", i: "Puerto Rico" },
            { v: "RI", i: "Rhode Island" },
            { v: "SC", i: "South Carolina" },
            { v: "SD", i: "South Dakota" },
            { v: "TN", i: "Tennessee" },
            { v: "TX", i: "Texas" },
            { v: "UT", i: "Utah" },
            { v: "VT", i: "Vermont" },
            { v: "VI", i: "Virgin Islands" },
            { v: "VA", i: "Virginia" },
            { v: "WA", i: "Washington" },
            { v: "WV", i: "West Virginia" },
            { v: "WI", i: "Wisconsin" },
            { v: "WY", i: "Wyoming" },
          ],
        },
        {
          country: "Canada",
          city: [
            { v: "AB", i: "Alberta" },
            { v: "BC", i: "British Columbia" },
            { v: "MB", i: "Manitoba" },
            { v: "NB", i: "New Brunswick" },
            { v: "NL", i: "Newfoundland and Labrador" },
            { v: "NT", i: "Northwest Territories" },
            { v: "NS", i: "Nova Scotia" },
            { v: "NU", i: "Nunavut" },
            { v: "ON", i: "Ontario" },
            { v: "PE", i: "Prince Edward Island" },
            { v: "QC", i: "Quebec" },
            { v: "SK", i: "Saskatchewan" },
            { v: "YU", i: "Yukon Territory" },
          ],
        },
        {
          country: "Other",
          city: [],
        },
      ],
      Account: {
        company: "",
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        password1: "",
        mobile: "",
        fax: "",
        website: "",
        additional_email: "",
        billing: {
          first_name: "",
          last_name: "",
          phone: "",
          company: "",
          address_type: "",
          address1: "",
          address2: "",
          suite: "",
          city: "",
          country: "",
          state: "",
          zip_code: "",
        },
        shipping: {
          first_name: "",
          last_name: "",
          phone: "",
          company: "",
          address_type: "",
          address1: "",
          address2: "",
          suite: "",
          city: "",
          country: "",
          state: "",
          zip_code: "",
        },
      },
      billing: "",
      shipping: "",
    };
  },
  methods: {
    submit() {
      console.log(this.clickButton);
      if (!this._data.clickButton) {
        return;
      }
      let error = [];
      let verify = /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
      if (this.Account.company == "") {
        error.push("Please enter your Company Name");
      }
      if (this.Account.first_name == "") {
        error.push("Please enter your First Name");
      }
      if (this.Account.last_name == "") {
        error.push("Please enter your Last Name");
      }
      if (this.Account.email == "") {
        error.push(" Please enter your Email Address");
      } else if (!verify.test(this.Account.email)) {
        error.push(" Please enter a valid Email Address");
      }
      if (this.Account.password == "") {
        error.push("Please enter your Password  ");
      } else if (this.Account.password.length < 6) {
        error.push("Passwords need a minimum of 6 digits ");
      }
      if (this.Account.password1 == "") {
        error.push(" Please re-enter your Password");
      } else if (this.Account.password != this.Account.password1) {
        error.push(" The two passwords are different ");
      }
      if (this.Account.mobile == "") {
        error.push("Please enter the Mobile");
      }
      //.billing
      if (this.Account.billing.first_name == "") {
        error.push("Please enter the Billing First Name");
      }
      if (this.Account.billing.last_name == "") {
        error.push("Please enter the Billing Last Name");
      }
      if (this.Account.billing.phone == "") {
        error.push(" Please enter the Billing Phone Number");
      }
      if (this.Account.billing.company == "") {
        error.push("  Please enter the Billing Company Name");
      }

      if (this.Account.billing.address1 == "") {
        error.push(" Please enter the Billing Address");
      }
      if (this.Account.billing.country == "") {
        error.push(" Please enter the Billing State/Province/Region");
      }
      if (this.Account.billing.city == "") {
        error.push(" Please enter the Billing City or APO/AFO");
      }

      if (this.Account.billing.zip_code == "") {
        error.push("  Please enter the Billing Zip/Postal Code");
      }
      //.shipping
      if (this.Account.shipping.first_name == "") {
        error.push("Please enter the shipping First Name");
      }
      if (this.Account.shipping.last_name == "") {
        error.push("Please enter the shipping Last Name");
      }
      if (this.Account.shipping.phone == "") {
        error.push(" Please enter the shipping Phone Number");
      }
      if (this.Account.shipping.company == "") {
        error.push("  Please enter the shipping Company Name");
      }

      if (this.Account.shipping.address1 == "") {
        error.push(" Please enter the shipping Address");
      }
      if (this.Account.shipping.country == "") {
        error.push(" Please enter the shipping State/Province/Region");
      }
      if (this.Account.shipping.city == "") {
        error.push(" Please enter the shipping City or APO/AFO");
      }

      if (this.Account.shipping.zip_code == "") {
        error.push("  Please enter the shipping Zip/Postal Code");
      }

      if (error.length == 0) {
        const loading = this.$loading({
          lock: true, //lock的修改符--默认是false
          text: "Loading", //显示在加载图标下方的加载文案
          spinner: "el-icon-loading", //自定义加载图标类名
          background: "rgba(0, 0, 0, 0.7)", //遮罩层颜色
          target: document.querySelector("#table"), //loadin覆盖的dom元素节点
        });
        this.shipping = JSON.stringify(this.Account.shipping);
        this.billing = JSON.stringify(this.Account.billing);
        this._data.clickButton = false;
        register(
          this.Account.company,
          this.Account.first_name,
          this.Account.last_name,
          this.Account.email,
          this.Account.password,
          this.Account.tax_id,
          this.Account.mobile,
          this.Account.website,
          this.Account.additional_email,
          this.billing,
          this.shipping
        ).then((res) => {
          loading.close();
          if (res.data.code == 1) {
            this.$notify({
              message: res.data.msg,
              type: "success",
              duration: 10000,
              position: "top-left",
              showClose: false,
              offset: 300,
            });
            this.Account.company = "";
            this.Account.first_name = "";
            this.Account.last_name = "";
            this.Account.email = "";
            this.Account.password = "";
            this.Account.password1 = "";
            this.Account.tax_id = "";
            this.Account.mobile = "";
            this.Account.website = "";
            this.Account.additional_email = "";
            this.Account.billing.first_name = "";
            this.Account.billing.last_name = "";
            this.Account.billing.phone = "";
            this.Account.billing.company = "";
            this.Account.billing.address_type = "";
            this.Account.billing.address1 = "";
            this.Account.billing.address2 = "";
            this.Account.billing.suite = "";
            this.Account.billing.city = "";
            this.Account.billing.country = "";
            this.Account.billing.state = "";
            this.Account.billing.zip_code = "";

            this.Account.shipping.first_name = "";
            this.Account.shipping.last_name = "";
            this.Account.shipping.phone = "";
            this.Account.shipping.company = "";
            this.Account.shipping.address_type = "";
            this.Account.shipping.address1 = "";
            this.Account.shipping.address2 = "";
            this.Account.shipping.suite = "";
            this.Account.shipping.city = "";
            this.Account.shipping.country = "";
            this.Account.shipping.state = "";
            this.Account.shipping.zip_code = "";
          } else {
            this.$notify({
              message: res.data.msg,
              type: "error",
              duration: 10000,
              position: "top-left",
              showClose: false,
              offset: 300,
            });
          }
          this._data.clickButton = true;
        });
      } else {
        this.error = error;
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    },

    copy1() {
      this.Account.billing.first_name = this.Account.first_name;
      this.Account.billing.last_name = this.Account.last_name;
      this.Account.billing.company = this.Account.company;
    },

    copy2() {
      this.Account.shipping.first_name = this.Account.billing.first_name;
      this.Account.shipping.last_name = this.Account.billing.last_name;
      this.Account.shipping.phone = this.Account.billing.phone;
      this.Account.shipping.company = this.Account.billing.company;
      this.Account.shipping.address_type = this.Account.billing.address_type;
      this.Account.shipping.address1 = this.Account.billing.address1;
      this.Account.shipping.address2 = this.Account.billing.address2;
      this.Account.shipping.suite = this.Account.billing.suite;
      this.Account.shipping.city = this.Account.billing.city;
      this.Account.shipping.country = this.Account.billing.country;
      this.Account.shipping.state = this.Account.billing.state;
      this.Account.shipping.zip_code = this.Account.billing.zip_code;
    },
  },
};
</script>
<style scoped>
p {
  margin: 0;
}
.form p {
  color: #f56c6c;
  font-size: 0.4rem;
}
.register {
  border: 1px solid #ededed;
  box-shadow: 0.1rem 0.1rem 0.1rem #ededed;
  margin-top: 0.5rem;
  padding: 0.5rem;
}
.title {
  margin-top: 0.5rem;
  color: #75072e;
  font-size: 0.5rem;
}
.font {
  color: #737373;
  font-size: 0.4rem;
  display: flex;
  align-items: center;
}
.font p:last-child {
  margin-top: 0.25rem;
}
.font span {
  border: 1px solid #75072e;
  padding: 0.15rem;
  font-size: 0.3rem;
  color: #75072e;
  margin-left: 0.25rem;
  cursor: pointer;
}
.form {
  font-size: 0.35rem;
  color: #737373;
}
table {
  margin-top: 0.5rem;
  border-top: 1px solid #ccc;
}
.tr {
  width: 100%;
  margin-top: 0.5rem;
  display: flex;
}
.tr td:first-child {
  width: 30%;
  text-align: end;
  display: block;
  align-items: center;
}
.tr td:last-child {
  width: 68%;
  padding-left: 2%;
  display: block;
}
.tr td input {
  width: 80%;
  padding: 0.25rem;
  border: 1px solid #737373;
}
.button {
  padding: 1rem;
  font-size: 0.4rem;
  text-align: center;
}
.button span {
  border: 1px solid #75072e;
  padding: 0.15rem 0.25rem;
  color: #75072e;
  cursor: pointer;
}
.error {
  font-size: 0.3rem;
  color: red;
}

@media (max-width: 768px) {
  .tr {
    display: block;
  }
  .tr td:first-child {
    width: 100%;
    display: block;
    text-align: left;
  }
  .tr td:last-child {
    width: 100%;
    display: block;
    padding-left: 0;
  }
  .font {
    display: block;
  }
  .font p {
    margin-bottom: 0.25rem;
    margin-right: 0;
  }
  .font span {
    margin-left: 0;
  }
}
</style>